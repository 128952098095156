<!--
 * @Description: 搜索统计
 * @Date: 2019-12-24 15:17:08
 * @LastEditTime: 2020-07-13 18:31:06
 * @LastEditors: 前胡
 -->
<template>
  <div id="list">
    <a-form
      class="filter-condition-form mb-10"
      layout="inline"
      :form="form"
      @submit="handleFormSubmit"
      v-auth="'data_stat_search_page'"
    >
      <a-form-item>
        <a-input
          style="width:300px;"
          allowClear
          v-decorator="['search']"
          placeholder="关键词"
          @change="handleSearchInputChange"
        />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit">查询</a-button>
      </a-form-item>
    </a-form>
    <a-table
      :scroll="{ y: tableScrollY }"
      v-auth="'data_stat_search_page'"
      :columns="columns"
      :pagination="pagination"
      :dataSource="list"
      :locale="{ emptyText: '未找到相关数据' }"
      :loading="searchListLoading"
      @change="handleTableChange"
    ></a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "关键词",
    align: "left",
    width: 380,
    dataIndex: "search_words"
  },
  {
    title: "搜索次数",
    dataIndex: "pv",
    align: "center",
    width: 180,
    sorter: true,

  },
  {
    title: "搜索人数",
    dataIndex: "uv",
    align: "center",
    width: 180,
    sorter: true
  },
  {
    title: "最近搜索",
    align: "center",
    dataIndex: "update_time",
    width: 200,
    sorter: true,
  }
];

import { getSearchStat } from "@/service/goods";

export default {
  name: "SearchList",
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      columns,
      page: 1,
      list: [],
      searchListLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      searchParams: {
        ordering: undefined
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 300;
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    // 搜索查询按钮
    handleFormSubmit(e) {
      e.preventDefault();
      this.searchParams.ordering = undefined
      this.form.validateFields((err, values) => {
        if (!err) {
          this.pagination.current = 1;
          this.searchParams.search = values.search;
          this.getSearchList();
        }
      });
    },
    // 获取搜索结果
    async getSearchList() {
      this.searchListLoading = true;
      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ordering: this.searchParams.ordering,
        search_words: this.searchParams.search
      };
      const { err, res } = await getSearchStat(data);

      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      }
      this.searchListLoading = false;
    },
    // 表格分页
    handleTableChange(pagination, filters, sorter) {
      this.formatSortData(sorter);
      if (pagination) this.pagination.current = pagination.current;
      this.getSearchList();
    },
    // sort格式化
    formatSortData(sortData) {
      let ordering = "";
      switch (sortData.field) {
        case "update_time":
          ordering =
            sortData.order === "ascend" ? "update_time" : "-update_time";
          break;
        case "pv":
          ordering = sortData.order === "ascend" ? "pv" : "-pv";
          break;
        case "uv":
          ordering = sortData.order === "ascend" ? "uv" : "-uv";
          break;
        default:
          break;
      }
      this.searchParams.ordering = ordering;
    },
    // 搜索框数据发生变化
    handleSearchInputChange(e) {
      if (e.target.value === "") this.getSearchList();
    }
  }
};
</script>
<style lang="less">
.mb-10 {
  margin-bottom: 10px;
}
</style>
